import React from "react";
import { Modal } from "@fashionphile/component-library";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { optionsModal } from "../../../helpers/contentful/options";

const ModalImages = ({
  imageUploadModal,
  setShowImagesModal,
}): React.ReactElement => {
  const description = (): React.ReactElement => (
    <>{documentToReactComponents(imageUploadModal, optionsModal)}</>
  );
  return (
    <Modal
      size="md"
      className="image-details-modal"
      ariaLabel="ImagesModal"
      titleId="customModalTitle"
      descriptionId=""
      title="Photography tips"
      description={description()}
      closeOnClick={(): void => {
        setShowImagesModal(false);
      }}
      buttonOnClick={(): void => {
        setShowImagesModal(false);
      }}
      buttonContent="Close"
    />
  );
};
export default ModalImages;
