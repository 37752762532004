import React, { useState } from "react";
import { Icon } from "@fashionphile/component-library";

const name = "description";
const ItemDescriptionField = ({
  errors,
  setShowCommentsModal,
  register,
  translate,
}): React.ReactElement => {
  const [isFocusVisible, setIsFocusVisible] = useState(false);

  const handleFocusVisible = (event): void => {
    console.log("handleFocusVisible");
    if (event.key === "Tab") {
      console.log("detected Tab");
      setIsFocusVisible(true);
    }
  };

  const handleFocusBlur = (): void => {
    setIsFocusVisible(false);
  };

  return (
    <div className="custom-form-group">
      <label
        className={`custom-label ${errors?.[name] ? "error" : ""}`}
        form="comments"
      >
        {translate("Item details and condition")}{" "}
        <a
          tabIndex={0}
          className="more-info"
          onClick={(): void => setShowCommentsModal(true)}
          onKeyPress={(): void => setShowCommentsModal(true)}
        >
          <Icon
            variant={`${errors?.[name] ? "red" : "primary"}`}
            name="icon-question-circle-solid"
            className="question-circle-overwrite"
          />
        </a>
      </label>
      <textarea
        className={`custom-textarea-input ${
          isFocusVisible && "focus-visible"
        } ${errors?.[name] ? "error" : ""}`}
        name={name}
        id={name}
        tabIndex={0}
        autoComplete="off"
        aria-label="Item details and condition"
        {...register(name, {
          required: translate("Enter item details and condition"),
        })}
        onBlur={handleFocusBlur}
        onKeyUp={handleFocusVisible}
      />
      {errors?.[name] && (
        <div className="help-block error">
          <Icon variant="red" name="icon-exclamation-triangle-solid" />
          {translate("Enter item details and condition")}
        </div>
      )}
    </div>
  );
};

export default ItemDescriptionField;
