import React, { useContext } from "react";
import { Button } from "@fashionphile/component-library";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useRouter } from "next/router";
import { optionsSYB } from "../../../helpers/contentful/options";
import { TranslateContext } from "../../context/TranslateContext";
import { useViewPageEvent } from "../../../events/event-helper";
import { EventTypes } from "../../../events/mobileApp/mobileAppEvents.types";
import { useMobileAppData } from "../../context/MobileAppContext";

const LoginState: React.FC<{ sybContentfulData: any }> = ({
  sybContentfulData,
}) => {
  const { loginData } = sybContentfulData;
  const isMobileAppListening = useMobileAppData();
  const _translate = useContext(TranslateContext);
  const { translate } = _translate;
  const EUregion = process.env.NEXT_PUBLIC_REGION === "EU";
  const { asPath } = useRouter();

  const handleClickSignUp = (e: any): void => {
    if (isMobileAppListening) {
      window.dispatchEvent(new CustomEvent(EventTypes.LOGIN));
    } else if (!e.key || e.key === "Enter") {
      window.parent.location.href = EUregion
        ? `${process.env.NEXT_PUBLIC_EU_MY_ACCOUNT}/auth`
        : `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/login?signup`;
    }
  };

  const getSellFormPageViewedEventName = (): string => {
    if (asPath === "/sell-your-bag/consignment") {
      return "/sell-your-bag/consignment";
    }
    if (asPath?.includes("?key")) {
      return "/sell-your-bag?key";
    }
    return "/sell-your-bag";
  };

  useViewPageEvent(getSellFormPageViewedEventName());

  return (
    <div className="form-wrapper background-color-blue" data-testid="Login">
      <ol className="login-ol" style={{ paddingBottom: "15px" }}>
        <li>
          <span className="num">1</span>
          {documentToReactComponents(loginData?.step1, optionsSYB)}
          <div className="login-start-a-quote">
            <Button
              type="submit"
              width="100%"
              ariaLabel="Start a quote button"
              content={translate("Start a Quote")}
              tabIndex={0}
              onClick={handleClickSignUp}
              className="startQuoteButton"
            />
          </div>
          <span>
            {/* Contentful link not working on subdomain - {documentToReactComponents(
              loginData?.loginOptionSecondaryAction,
              optionsSYB
            )} */}
            <p className="body-lg landing-text">
              {translate("Already have an account?")}{" "}
              <a
                onClick={handleClickSignUp}
                onKeyDown={handleClickSignUp}
                tabIndex={0}
                data-testid="Sign-in"
              >
                {translate("Sign in")}
              </a>
            </p>
          </span>
        </li>
        <hr />
        <li>
          <span className="num">2</span>
          {documentToReactComponents(loginData?.step2, optionsSYB)}
        </li>
        <hr />
        <li>
          <span className="num">3</span>
          {documentToReactComponents(loginData?.step3, optionsSYB)}
        </li>
      </ol>
      <p className="body-md">{loginData?.disclaimerText}</p>
    </div>
  );
};

export default LoginState;
