export const formatPhoneNumber = (
  value: string
): { parsed: string; raw: string } => {
  if (!value) {
    return { parsed: undefined, raw: undefined };
  }

  const raw = value.replace(/\D/g, "");
  const len = raw.length;

  if (len > 3 && len < 10) {
    return { parsed: `(${raw.slice(0, 3)}) ${raw.slice(3)}`, raw };
  }
  if (len === 10) {
    return {
      parsed: `(${raw.slice(0, 3)}) ${raw.slice(3, 6)}-${raw.slice(6, 10)}`,
      raw,
    };
  }
  if (len === 11 && raw.startsWith("1")) {
    return {
      parsed: `(${raw.slice(1, 4)}) ${raw.slice(4, 7)}-${raw.slice(7, 11)}`,
      raw,
    };
  }
  return { parsed: raw, raw };
};
