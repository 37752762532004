import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Button, Icon } from "@fashionphile/component-library";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { captureException } from "@sentry/nextjs";
import { datadogLogs } from "@datadog/browser-logs";
import sortBy from "lodash.sortby";
import events from "../../events";
import {
  useEmitOnce,
  useEventModal,
  useViewPageEvent,
} from "../../events/event-helper";
import { categoryOrder } from "../../helpers/defaultCategories";
import { optionsLegalText } from "../../helpers/contentful/options";
import fixture from "../../helpers/fixtures/designerDirectoryFixture";
import { getUserContext } from "../context/UserContext";
import { getTranslateContext } from "../context/TranslateContext";
import BrandSelect from "../FormFields/BrandSelect";
import CategorySelect from "../FormFields/CategorySelect";
import ItemNameField from "../FormFields/ItemNameField";
import ItemDescriptionField from "../FormFields/ItemDescriptionField";
import { cleanDblQuotes } from "../../helpers/util";
import { BaseData } from "../../@types/ItemSurvey";
import { BrandData, Brands } from "../../@types/brands";
import axiosInstance from "../../API/axios-config";
import Loading from "../Loading/Loading";
import { StateFile } from "../../@types/ImageUploader";
import { Quote, PendingItem } from "../../@types/quote";
import { getDesignerDir } from "../../API/async";
import { COOKIES } from "../../events/cookies";
import SYBForm from "../../@types/syb-form";
import { EventTypes } from "../../events/mobileApp/mobileAppEvents.types";
import { useMobileAppData } from "../context/MobileAppContext";
import { transformPendingItemToQuote } from "../../helpers/quote/transformPendingItemToQuote";
import ModalPhoneNumber from "./modals/ModalPhoneNumber";
import ModalSuccess from "./modals/ModalSuccess";
import ModalError from "./modals/ModalError";
import ModalImages from "./modals/ModalImages";
import ModalComments from "./modals/ModalComments";
import ModalWrongEmail from "./modals/ModalWrongEmail";
import ModalPhoneSuccess from "./modals/ModalPhoneSuccess";
import ImageUploaderProxy from "./ImageUploaderProxy";
import { SellYourBagBodyProps } from "./SellYourBagBody.props";

const formDefaultValues: SYBForm = {
  currentBrand: "",
  currentCategory: "",
  itemName: "",
  description: "",
};

const { logger } = datadogLogs;

const SellYourBagBody: React.FC<SellYourBagBodyProps> = ({
  updateSubmissions,
  itemDetailsModal,
  imageUploadModal,
  legalText,
  draftContent,
  resetDraft,
}) => {
  const {
    user: { email, userId },
  } = getUserContext();
  const { translate } = getTranslateContext();
  const isMobileAppListening = useMobileAppData();
  const identityId = Cookies.get(COOKIES.UUID);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: formDefaultValues,
  });
  const formRef = useRef<HTMLFormElement>(null);

  const [brands, setBrands] = useState<[string, BrandData][]>([]);
  const [categories, setCategories] = useState<Brands>();
  const [categoryList, setCategoryList] = useState<BaseData[]>([]);
  const [draftId, setDraftId] = useState<number>(null);
  const [referrer, setReferrer] = useState("");
  const [submitResponse, setSubmitResponse] = useState<Quote>();
  const [version, setVersion] = useState<number>(1);
  const [currentBrand, currentCategory] = watch([
    "currentBrand",
    "currentCategory",
  ]);
  const [modalPhoneNumber, setModalPhoneNumber] = useState<string>();

  // image handling
  const [images, setImages] = useState<StateFile[]>([]);
  const [fileIds, setFileIds] = useState<number[]>([]);
  const [imageError, setImageError] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  // error handling
  const [disabled, setDisabled] = useState(false);
  const [loadingContent, setLoadingContent] = useState("");
  const [hasReset, setHasReset] = useState(false);
  const [touchedAfterReset, setTouchedAfterReset] = useState(false);

  // modals
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showSubmitErrorModal, setShowSubmitErrorModal] = useState(false);
  const [showPhoneSuccessModal, setShowPhoneSuccessModal] = useState(false);
  const [showWrongEmailModal, setShowWrongEmailModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useEventModal(
    "web quote text a link",
    identityId
  );
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useEventModal(
    "web quote submitted",
    identityId
  );

  const { emitOnce, updateEmitOnce, resetEmitOnce } = useEmitOnce("photo");

  const resetErrors = (): void => {
    clearErrors();
    setImageError("");
    setDisabled(false);
  };

  const resetForm = (): void => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    reset(formDefaultValues);
    resetEmitOnce();
    setFileIds([]);
    setImages([]);
    setReferrer("");
    resetDraft();
    setHasReset(true);
    setTouchedAfterReset(false);
  };

  const router = useRouter();
  const { query } = router;

  const fetchData = useCallback(async () => {
    let brandList: [string, BrandData][] = [];
    let categories: Brands = {};
    try {
      categories = await getDesignerDir();
      brandList = sortBy(Object.entries(categories), [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, brandData]) => brandData.name,
      ]);
    } catch (err) {
      brandList = Object.entries(fixture);
      categories = fixture;
    }
    const selectObj = {
      name: translate("Select a Designer"),
      categories: [],
    };
    brandList.unshift(["-1", selectObj]);
    categories["-1"] = selectObj;
    setBrands(brandList);
    setCategories(categories);
  }, []);

  const getSellFormPageViewedEventName = (): string => {
    if (router.asPath === "/sell-your-bag/consignment") {
      return "/sell-your-bag/consignment";
    }
    if (router.asPath?.includes("?key")) {
      return "/sell-your-bag?key";
    }
    return "/sell-your-bag";
  };

  const emitSellFormPageViewed = (): void => {
    /* eslint-disable camelcase */
    const page_name = getSellFormPageViewedEventName();
    events.emit("page_view", {
      page_name,
      userId,
    });
    /* eslint-enable camelcase */
  };

  useEffect(() => {
    fetchData();
  }, []);

  useViewPageEvent(getSellFormPageViewedEventName(), () =>
    events.emit("sell_form_view", {
      customer_id: `${userId}` || Cookies.get(COOKIES.UUID),
    })
  );

  useEffect(() => {
    if (draftContent) {
      (async () => {
        try {
          await fetchData();
          if (email && draftContent?.email && draftContent?.email !== email) {
            setShowWrongEmailModal(true);
          } else {
            const { id, brandId, categoryId, name, description } = draftContent;
            setValue("currentBrand", `${brandId}`);
            setValue("currentCategory", `${categoryId}`);
            setValue("description", description);
            setValue("itemName", name);
            setDraftId(id);
          }
        } catch (e) {
          setShowSubmitErrorModal(true);
        }
      })();
    }
  }, [draftContent, email]);

  useEffect(() => {
    if (query.brand_id || query.category_id || query.name || query.referrer) {
      // allow time for select to populate
      setTimeout(() => {
        setValue("currentBrand", query.brand_id as string);
        setValue("currentCategory", query.category_id as string);
        setValue("itemName", query.name as string);
        setReferrer(query.referrer as string);
      }, 1000);
    }
  }, [query]);

  useEffect(() => {
    // if error, scroll to top of form
    // input refs are broken due to missing support in CL
    const errorKey = Object.keys(errors)?.[0] as keyof SYBForm;
    if (errorKey) {
      const offset = -24; // px
      const refTop = formRef.current.getBoundingClientRect().top;
      const top = refTop + window.scrollY + offset;
      window.scrollTo({ top, behavior: "smooth" });
    }
  }, [errors]);

  // brand meta
  const hasCurrentBrand = useMemo(
    () =>
      currentBrand &&
      currentBrand !== "undefined" &&
      currentBrand !== "null" &&
      currentBrand !== "-1",
    [currentBrand]
  );
  const currentCategories = useMemo(() => {
    if (!categories || !hasCurrentBrand) {
      return [];
    }
    return categories[currentBrand].categories || [];
  }, [categories, currentBrand, hasCurrentBrand]);
  // category meta
  const hasCurrentCategory = useMemo(
    () =>
      currentCategory &&
      currentCategory !== "undefined" &&
      currentCategory !== "null" &&
      currentCategory !== "-1",
    [currentBrand, currentCategory]
  );

  useEffect(() => {
    if (!hasCurrentBrand) {
      return;
    }
    const currentBrandObj = brands?.find(
      (brand) => +brand[0] === +currentBrand
    );
    events.emit("syb_designer_selected", {
      brand_id: +currentBrand,
      category_id: currentCategory ? +currentCategory : null,
      step_value: currentBrandObj?.[1]?.name.toString(),
      user_id: userId,
      step: 2,
      step_name: "Designer Dropdown",
    });
  }, [currentBrand]);

  useEffect(() => {
    if (!hasCurrentCategory || !hasCurrentBrand) {
      return;
    }
    const currentCategoryName =
      currentCategories.find((category) => +category.id === +currentCategory)
        ?.name || "";

    events.emit("syb_category_selected", {
      category_id: +currentCategory,
      brand_id: currentBrand ? +currentBrand : null,
      step_name: "Category Dropdown",
      step_value: currentCategoryName,
      step: 3,
      user_id: userId,
    });
  }, [currentCategory]);

  const clearCookies = (): void => {
    Cookies.remove(COOKIES.UUID);
  };

  const notYou = (): void => {
    const querystring = encodeURIComponent(
      `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/sell-your-bag`
    );
    clearCookies();
    if (isMobileAppListening) {
      window.dispatchEvent(new CustomEvent(EventTypes.LOGIN));
    } else {
      window.parent.location.href = `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/notyou?redirect=${querystring}`;
    }
  };

  const updateErrors = useCallback(
    (ers: any): void => {
      Object.entries(ers).forEach(
        ([name, message]: [keyof SYBForm, string]) => {
          setError(name, {
            type: "custom",
            message,
          });
        }
      );
    },
    [setError]
  );

  // form validation
  const validate = (): boolean => {
    resetErrors();

    let imageError = "";
    const invalid: Partial<Record<keyof SYBForm, string>> = {};

    const { itemName, description } = getValues();

    if (!itemName || itemName?.trim() === "") {
      invalid.itemName = "Please enter an item name.";
    }
    if (!description || description?.trim() === "") {
      invalid.description = "Please enter a description.";
    }
    if (!hasCurrentBrand) {
      invalid.currentBrand = "Please select a designer.";
    }
    if (!hasCurrentCategory) {
      invalid.currentCategory = "Please select a category.";
    }

    if (imageUploading) {
      imageError = translate(
        "Your photos are still uploading. Please submit your quote after they are finished uploading."
      );
    } else if (!fileIds.length && !imageUploading) {
      imageError = translate("You must upload at least 1 image.");
    }

    if (Object.keys(invalid).length || imageError) {
      logger.warn("SYB Failed Validation", { ...invalid, imageError });
      setImageError(imageError);
      updateErrors(invalid);
      setDisabled(true);
      return false;
    }

    return true;
  };

  const openPhoneModal = async (): Promise<void> => {
    if (!currentBrand || currentBrand === "null") {
      setError("currentBrand", {
        type: "custom",
        message: "Please select a brand",
      });
    }
  };

  const submitHandler = async (values: SYBForm): Promise<void> => {
    if (disabled) {
      return;
    }
    if (!validate()) {
      return;
    }
    const data: Record<string, string | number | number[]> = {
      email,
      brand_id: +values.currentBrand,
      category_id: +values.currentCategory,
      name: cleanDblQuotes(values?.itemName).trim(),
      description: cleanDblQuotes(values?.description).trim(),
      referrer,
      imageIds: fileIds,
    };

    if (draftId) {
      data.quoteDraftId = draftId;
    }

    try {
      setDisabled(true);
      setLoadingContent("Submitting");
      const response = await axiosInstance.post(
        `${process.env.NEXT_PUBLIC_API_URL}/v2/quotes`,
        data
      );
      const pendingItem = response?.data as PendingItem;
      const quote = transformPendingItemToQuote(pendingItem);

      setSubmitResponse(quote);
      setShowSubmitSuccessModal(true);
      events.emit("syb_form_completed", {
        email,
        pending_item_id: quote?.id,
        quote_draft_id: draftId,
        quote_uuid: null,
        sell_form_type: "Web Quote",
        step_name: "Quote Form Completed",
        step: 5,
        user_id: userId,
      });
      resetForm();
      setVersion(version + 1);
    } catch (error) {
      captureException(error);
      logger.error("SYB Submission Error", { error, payload: data });
      setShowSubmitErrorModal(true);
    } finally {
      setDisabled(false);
      setLoadingContent("");
    }
  };

  useEffect(() => {
    if (!hasCurrentCategory) {
      setValue("currentCategory", "");
    }
    if (!hasCurrentBrand) {
      setCategoryList([]);
      return;
    }
    clearErrors("currentBrand");
    const list = currentCategories.sort(
      (a, b) =>
        categoryOrder[a.name] - categoryOrder[b.name] ||
        a.name.localeCompare(b.name)
    );
    list.unshift({
      id: -1,
      name: translate("Select a category"),
    });
    setCategoryList(list);
  }, [currentBrand]);

  const updateFileIds = (ids: number[]): void => {
    setImageError("");
    setFileIds(ids);
    if (ids.length && !emitOnce.photo) {
      events.emit("web_quote_image_upload", {
        step_value: `${ids.length} photo`,
        step: 4,
        user_id: userId,
        step_name: "Photo Upload",
        brand_id: currentBrand ? +currentBrand : null,
        category_id: currentCategory ? +currentCategory : null,
      });
      updateEmitOnce("photo");
    }
  };

  const handleFormClick = (): void => {
    // n.b. ReactHookForm#isDirty isn't consistent enough (here)
    if (hasReset && !touchedAfterReset) {
      events.emit("sell_form_view", {
        customer_id: `${userId}` || Cookies.get(COOKIES.UUID),
      });
      setTouchedAfterReset(true);
    }
  };

  return (
    <div className="form-wrapper background-color-blue" data-testid="Home">
      {loadingContent && <Loading />}
      <form
        noValidate
        className="fp-form-style"
        onClick={handleFormClick}
        onSubmit={handleSubmit(submitHandler, validate)}
        ref={(ref) => {
          formRef.current = ref;
        }}
      >
        <h2 className="h2">{translate("Welcome!")}</h2>
        <div data-content="signup">
          <div className="col-12 signup-info">
            <p className="body-lg">
              {translate("Email")}: {email}
            </p>
            <p className="body-lg">
              {translate("Not you?")}
              <a
                className="switch-account margin-left-xs"
                onClick={notYou}
                onKeyPress={notYou}
                tabIndex={0}
              >
                {translate("Sign in to My Account")}
              </a>
            </p>
            <h3 className="h3">{translate("What are you selling?")}</h3>
          </div>
        </div>
        <div className="row designer-field margin-bottom-sm">
          <div className="col-12">
            <BrandSelect
              errors={errors}
              control={control}
              hasCurrentBrand={hasCurrentBrand}
              currentBrand={currentBrand}
              brands={brands}
              translate={translate}
            />
          </div>
        </div>
        <div className="row category-field margin-bottom-sm">
          <div className="col-12">
            <CategorySelect
              errors={errors}
              control={control}
              hasCurrentCategory={hasCurrentCategory}
              categoryList={categoryList}
              currentCategory={currentCategory}
              translate={translate}
            />
          </div>
        </div>
        <div className="row margin-bottom-sm">
          <div className="col-12">
            <ItemNameField
              errors={errors}
              control={control}
              translate={translate}
              shouldSuggest={!!userId}
            />
          </div>
        </div>
        <div className="row margin-bottom-xs">
          <div className="col-12 form-comments">
            <h3 className="h3">
              {translate("Any details we should know about?")}
            </h3>
            <p className="body-lg">{translate("For example:")}</p>
            <ul className="body-lg">
              <li>
                {translate(
                  "Condition: light corner wear, odor, interior pen marks"
                )}
              </li>
              <li>
                {translate("Items included: shoulder strap and lock & key")}
              </li>
              <li>{translate("Other details: measurements & item age")}</li>
            </ul>
            <ItemDescriptionField
              errors={errors}
              setShowCommentsModal={setShowCommentsModal}
              register={register}
              translate={translate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-images">
            <div className="form-group custom-form-group">
              <h3 className="h3">
                {translate("Upload photos of your item")}
                <a
                  tabIndex={0}
                  className="more-info"
                  onClick={(): void => setShowImagesModal(true)}
                  onKeyPress={(): void => setShowImagesModal(true)}
                >
                  <Icon
                    className="question-circle-overwrite"
                    name="icon-question-circle-solid"
                  />
                </a>
              </h3>
              <p className="dropzone-desc body-lg">
                {translate(
                  "Show us your best shots! Photograph one item at a time on a solid white/color background and follow our image prompts."
                )}
              </p>
              {process.env.NEXT_PUBLIC_SHOW_SYB_DRAFT && (
                <div className="form-sub-group hidden-xs">
                  <strong className="fw-md body-lg">
                    {translate("Want to upload photos with your phone?")}
                  </strong>
                  {currentBrand ? (
                    <div className="description body-lg">
                      {translate(
                        "We'll text message you a link and finish completing your form on your phone."
                      )}
                      <a
                        tabIndex={0}
                        onClick={openPhoneModal}
                        onKeyDown={openPhoneModal}
                        className="link-default margin-left-xs"
                      >
                        {translate("Text me a link")}
                      </a>
                    </div>
                  ) : (
                    <div className="description body-lg">
                      Please select a designer / brand to continue on mobile.
                    </div>
                  )}
                </div>
              )}
              {currentCategory ? (
                <ImageUploaderProxy
                  images={images}
                  setImages={setImages}
                  categoryId={+currentCategory}
                  updateFileIds={updateFileIds}
                  setImageUploading={setImageUploading}
                  error={imageError}
                  key={version}
                  setDisabled={setDisabled}
                  translate={translate}
                />
              ) : (
                <div
                  className={`category-select-message ${
                    imageError ? "error" : ""
                  }`}
                >
                  <p
                    className="photo-select-message"
                    style={{ fontSize: "10.42px" }}
                  >
                    {imageError ? (
                      <div className="help-block error">
                        <Icon
                          variant="red"
                          name="icon-exclamation-triangle-solid"
                        />
                        {imageError}
                      </div>
                    ) : (
                      translate(
                        "Please select a category to begin uploading photos"
                      )
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="sell-submission">
          <Button
            type="submit"
            width="100%"
            ariaLabel="Submit my quote button"
            content={translate("Submit My Quote")}
            tabIndex={0}
            disabled={disabled}
          />
        </div>
        {documentToReactComponents(legalText, optionsLegalText)}
      </form>

      {showCommentsModal && (
        <ModalComments
          itemDetailsModal={itemDetailsModal}
          setShowCommentsModal={setShowCommentsModal}
        />
      )}
      {showImagesModal && (
        <ModalImages
          imageUploadModal={imageUploadModal}
          setShowImagesModal={setShowImagesModal}
        />
      )}

      {showSubmitSuccessModal && (
        <ModalSuccess
          setShowSubmitSuccessModal={setShowSubmitSuccessModal}
          updateSubmissions={updateSubmissions}
          email={email}
          submitResponse={submitResponse}
          emitSellFormViewedEvent={emitSellFormPageViewed}
          resetForm={resetForm}
        />
      )}

      {showPhoneNumberModal && (
        <ModalPhoneNumber
          setModalPhoneNumber={setModalPhoneNumber}
          setShowPhoneNumberModal={setShowPhoneNumberModal}
          setShowPhoneSuccessModal={setShowPhoneSuccessModal}
          setShowSubmitErrorModal={setShowSubmitErrorModal}
          draftId={draftId}
        />
      )}

      {showPhoneSuccessModal && (
        <ModalPhoneSuccess
          setShowPhoneSuccessModal={setShowPhoneSuccessModal}
          phoneNumber={modalPhoneNumber}
        />
      )}

      {showWrongEmailModal && (
        <ModalWrongEmail
          setShowWrongEmailModal={setShowWrongEmailModal}
          notYou={notYou}
          clearCookies={clearCookies}
        />
      )}

      {showSubmitErrorModal && (
        <ModalError
          submit={handleSubmit(submitHandler)}
          setShowSubmitErrorModal={setShowSubmitErrorModal}
        />
      )}
    </div>
  );
};

export default SellYourBagBody;
