import React, { Suspense, SuspenseProps, useEffect, useState } from "react";

const ClientBoundary: React.FC<Partial<SuspenseProps>> = ({
  children,
  fallback = <div />,
}) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => setHasMounted(true), []);

  return <Suspense fallback={fallback}>{hasMounted && children}</Suspense>;
};

export const clientBoundaryHOC = <P extends unknown>(
  Child: React.FC<P>
): React.FC<P> => (props) => (
  <ClientBoundary>
    <Child {...props} />
  </ClientBoundary>
);

export default ClientBoundary;
