import React from "react";
import { Modal } from "@fashionphile/component-library";
import { Quote } from "../../../@types/quote";

interface Props {
  email: string;
  submitResponse: Quote;
  updateSubmissions: () => void;
  setShowSubmitSuccessModal: (state: boolean) => void;
  emitSellFormViewedEvent: () => void;
  resetForm: () => void;
}

const ModalSuccess: React.FC<Props> = ({
  email,
  emitSellFormViewedEvent,
  resetForm,
  setShowSubmitSuccessModal,
  submitResponse,
  updateSubmissions,
}) => {
  const description = (
    <>
      Thank you for submitting your item to sell! We will send you a quote to{" "}
      <b>{email}</b> typically within 3-5 business days.
      {submitResponse && (
        <span> Your quote reference id is #{submitResponse.id}.</span>
      )}
    </>
  );

  const handleClose = (): void => {
    setShowSubmitSuccessModal(false);
    updateSubmissions();
    emitSellFormViewedEvent();
    resetForm();
  };

  return (
    <Modal
      size="sm"
      ariaLabel="SuccessModal"
      titleId="customModalTitle"
      descriptionId=""
      linkPlaceHolder="shop new arrivals"
      linkHref={`${process.env.NEXT_PUBLIC_WEB_APP_URL}/shop/new-arrivals`}
      className="submission-modal"
      title="Submitted!"
      description={description}
      buttonContent="Get another quote"
      closeOnClick={handleClose}
      buttonOnClick={handleClose}
    />
  );
};
export default ModalSuccess;
