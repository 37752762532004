import { Quote, PendingItem } from "../../@types/quote";

export const transformPendingItemToQuote = (
  pendingItem: PendingItem
): Quote => {
  const item = pendingItem.data;
  return {
    id: parseInt(item.id),
    quoteUuid: null,
    sellerId: null,
    name: item.attributes.name,
    images: item.attributes.images.map((img) => img.url),
    brand: { id: null, name: item.attributes.brand },
    category: { id: null, name: item.attributes.category },
    paymentMethod: null,
    paymentStatus: null,
    status: null,
    offer: {
      amount: null,
      id: null,
      offerType: null,
      quoteId: null,
      acceptedAt: null,
      createdAt: null,
      expiresAt: null,
      offerExpiration: null,
      updatedAt: null,
    },
    authenticationStatus: null,
    arrivedAt: null,
    receivedAt: null,
    createdAt: item.attributes.createdAt,
    updatedAt: null,
  };
};
