import React from "react";
import { Controller } from "react-hook-form";
import { PhoneInput } from "@fashionphile/component-library";
import { getTranslateContext } from "../context/TranslateContext";
import { LooseControlBase } from "../../@types/control";

interface Props extends LooseControlBase {
  label?: string;
  id?: string;
}

const Phone = ({
  errors,
  control,
  label = "Phone Number",
  id = "phone",
}: Props): React.ReactElement => {
  const { translate } = getTranslateContext();
  const validatePhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    return (
      phoneNumberDigits.length >= 10 ||
      translate("Please enter a minimum of 10 digits")
    );
  };

  return (
    <Controller
      render={({ field: { onChange, value } }): React.ReactElement => (
        <PhoneInput
          label={label}
          id={id}
          ariaLabel="form phone"
          onChange={onChange}
          value={value}
          error={errors.phone?.message}
        />
      )}
      rules={{
        required: translate("Phone number is required"),
        validate: validatePhoneNumber,
      }}
      name={id}
      control={control}
    />
  );
};
export default Phone;
