import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Button, Modal } from "@fashionphile/component-library";
import { useForm } from "react-hook-form";
import Phone from "../../FormFields/Phone";
import { axiosInstanceNoAuth } from "../../../API/axios-config";
import { formatPhoneNumber } from "../../../helpers/formHelpers/formatPhoneNumber";

interface Props {
  draftId: number;
  setModalPhoneNumber: Dispatch<SetStateAction<string>>;
  setShowPhoneNumberModal: Dispatch<SetStateAction<boolean>>;
  setShowPhoneSuccessModal: Dispatch<SetStateAction<boolean>>;
  setShowSubmitErrorModal: Dispatch<SetStateAction<boolean>>;
}

const ModalPhoneNumber: React.FC<Props> = ({
  draftId,
  setModalPhoneNumber,
  setShowPhoneNumberModal,
  setShowPhoneSuccessModal,
  setShowSubmitErrorModal,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      phone: "",
    },
  });

  const onClose = (): void => {
    setShowPhoneNumberModal(false);
  };

  const onSubmit = async ({ phone }: { phone: string }): Promise<void> => {
    const formattedPhone = formatPhoneNumber(phone);
    setModalPhoneNumber(formattedPhone.parsed);
    const data = {
      id: draftId,
      phoneNumber: `1${formattedPhone.raw}`,
    };

    try {
      await axiosInstanceNoAuth.post(
        `${process.env.NEXT_PUBLIC_NODE_APP_URL}/quotes/drafts/sendLink`,
        data
      );
      setShowPhoneSuccessModal(true);
    } catch (e) {
      setShowSubmitErrorModal(true);
    }
    setShowPhoneNumberModal(false);
  };

  const description = useMemo(
    () => (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <p>
          Submitting a quote is easier than ever! Enter your mobile phone number
          below and we&apos;ll text you a link to continue your quote. You can
          choose to take new photos or upload existing one straight from your
          mobile device.
        </p>
        <Phone errors={errors} control={control} label="Mobile phone number" />
        <p className="body-md">
          By providing your phone number, you agree to receive a one-time
          message with a link to complete your submission. Msg &amp; data rates
          may apply.
        </p>
        <div className="modal-phone-number-actions">
          <Button
            onClick={onClose}
            ariaLabel="close modal"
            content="Cancel"
            variant="clear"
          />
          <Button
            ariaLabel="submit number"
            type="submit"
            content="TEXT ME A LINK"
          />
        </div>
      </form>
    ),
    [errors, control]
  );

  return (
    <Modal
      size="md"
      ariaLabel="PhoneNumberModal"
      titleId="customModalTitle"
      descriptionId=""
      title="Upload photos with your phone"
      description={description}
      closeOnClick={onClose}
    />
  );
};
export default ModalPhoneNumber;
