import React from "react";
import { Modal } from "@fashionphile/component-library";

const ModalWrongEmail = ({
  setShowWrongEmailModal,
  notYou,
  clearCookies,
}): React.ReactElement => {
  const description = (): React.ReactElement => (
    <>
      This link is associated with a different account. Please sign in to the
      account you created the draft with.
    </>
  );

  const closeOnClick = (): void => {
    setShowWrongEmailModal(false);
    clearCookies();
  };
  const buttonOnClick = (): void => {
    setShowWrongEmailModal(false);
    notYou();
  };

  return (
    <Modal
      classNameModal="WrongEmailModal"
      ariaLabel="WrongEmailModal"
      titleId="WrongEmail"
      descriptionId=""
      title="Using a different email?"
      description={description()}
      buttonContent="Sign In"
      linkPlaceHolder="Submit a new quote"
      closeOnClick={closeOnClick}
      buttonOnClick={buttonOnClick}
    />
  );
};
export default ModalWrongEmail;
