import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "@fashionphile/component-library";

interface Props {
  setShowPhoneSuccessModal: Dispatch<SetStateAction<boolean>>;
  phoneNumber: string;
}

const ModalPhoneSuccess: React.FC<Props> = ({
  setShowPhoneSuccessModal,
  phoneNumber,
}) => (
  <Modal
    size="sm"
    ariaLabel="PhoneNumberModal"
    titleId="customModalTitle"
    descriptionId=""
    title="You're all set!"
    description={`Your text message has been sent to your mobile number: ${phoneNumber}`}
    closeOnClick={(): void => {
      setShowPhoneSuccessModal(false);
    }}
  />
);
export default ModalPhoneSuccess;
