import React from "react";
import { Modal } from "@fashionphile/component-library";

const ModalBanned = (): React.ReactElement => {
  const description = (): React.ReactElement => (
    <>
      <p>
        Your account has been suspended from all selling activity due to issues
        with one or more of your previously submitted items.
      </p>
      <p>
        If you believe this decision is incorrect, please contact our Client
        Services team for assistance.
      </p>
    </>
  );
  return (
    <Modal
      size="sm"
      ariaLabel="BannedModel"
      titleId="bannedModelTitle"
      descriptionId=""
      title="Notice of Account Suspension"
      description={description()}
      hasCloseButton={false}
      closeOnClick={(): void => null}
    />
  );
};
export default ModalBanned;
