import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const HowItWorks = ({ sybContentfulData }): React.ReactElement => (
  <div className="how-it-works-container">
    <h3 className="h3">{sybContentfulData.designerDirectory.header}</h3>
    <div className="designer-directory-link-container">
      <div className="body-lg">
        <p>
          {sybContentfulData.designerDirectory.text}
          {` `}
        </p>
        <br className="break-line" />
        {documentToReactComponents(sybContentfulData.designerDirectory.content)}
      </div>
    </div>
  </div>
);

export default HowItWorks;
