import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@fashionphile/component-library";
import { useRouter } from "next/router";
import fixture from "../../helpers/fixtures/designerDirectoryFixture";
import { getDesignerDir } from "../../API/async";
import { BrandEntries } from "../../@types/brands";
import { useMobileAppData } from "../context/MobileAppContext";
import { EventTypes } from "../../events/mobileApp/mobileAppEvents.types";

const accountURL = process.env.NEXT_PUBLIC_ACCOUNT_URL;

interface Props {
  thumbnailUrl: string;
  currentBrand: number;
  name: string;
  quoteId: number;
}

const QuoteInProgress: React.FC<Props> = ({
  thumbnailUrl,
  currentBrand,
  quoteId,
  name,
}) => {
  const [brands, setBrands] = useState<BrandEntries>([]);

  const fetchData = useCallback(async () => {
    try {
      const data = await getDesignerDir();
      setBrands(Object.entries(data));
    } catch (err) {
      console.log("fetch Designer Directory", err);
      setBrands(Object.entries(fixture));
    }
  }, [brands]);

  useEffect(() => {
    fetchData();
  }, []);

  if (!quoteId) {
    return <div />;
  }

  const brandQuery =
    brands?.find((brand) => parseInt(brand[0]) === currentBrand) || [];

  const brandName = brandQuery[1]?.name || "";

  const route = useRouter();
  const isMobileAppListening = useMobileAppData();

  const handleUrlRouting = (url: string, eventType: string): void => {
    if (isMobileAppListening) {
      window.dispatchEvent(new CustomEvent(eventType, { detail: { url } }));
    } else {
      route.push(url);
    }
  };
  return (
    <div className="quote-in-progress">
      <h1 className="submit-title h1-semibold"> ITEM SUBMITTED </h1>
      <p className="body-lg">
        We’ve received your submission and are working on{" "}
        <b>Quote #{quoteId}.</b>
        <br />
        <br />
        Please visit{" "}
        <a
          onClick={() => {
            handleUrlRouting(
              `${accountURL}/account/sales/quotes`,
              EventTypes.OPEN_MY_ACCOUNT_URL
            );
          }}
          href={
            isMobileAppListening
              ? undefined
              : `${accountURL}/account/sales/quotes`
          }
          tabIndex={0}
          className="underline-link"
        >
          My Account
        </a>{" "}
        to track your item.
      </p>
      <div className="item-summary-box">
        <div className="thumbnail-wrapper">
          <img
            src={thumbnailUrl || "https://www.fashionphile.com/images/knot.svg"}
            alt={name}
          />
        </div>
        <div className="names-container">
          <h5 className="brand-name h5-semibold">{brandName}</h5>
          <p className="item-name">{name}</p>
        </div>
      </div>
      <div className="button-group">
        <div className="button-spacer">
          <Button
            onClick={() => {
              handleUrlRouting(
                `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/account/sales/quotes`,
                EventTypes.OPEN_MY_ACCOUNT_URL
              );
            }}
            ariaLabel="button"
            content="TRACK MY ITEM"
            size="lg"
            tabIndex={0}
            type="button"
            variant="primary"
            width="100%"
          />
        </div>
        <div className="button-spacer">
          <Button
            onClick={() => {
              handleUrlRouting("/sell-your-bag", EventTypes.SELLER_OPEN);
            }}
            ariaLabel="button"
            className="border-button"
            content="SUBMIT ANOTHER ITEM"
            size="lg"
            tabIndex={0}
            type="button"
            variant="ghost-black"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteInProgress;
