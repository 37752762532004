import React, { ChangeEvent, useState } from "react";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { FormAutocomplete } from "@fashionphile/component-library";
import { Suggestion } from "@fashionphile/component-library/build/Components/FormAutocomplete/FormAutocomplete.types";
import { getProductSuggestions } from "../../API/async";

const name = "itemName";

const ItemNameField = ({
  errors,
  control,
  translate,
  shouldSuggest = true,
}): React.ReactElement => {
  const [suggestions, setSuggestions] = useState<string[] | Suggestion[]>([]);

  const handleOnChange = (
    controllerOnChange: ControllerRenderProps["onChange"]
  ) => async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.preventDefault();
    const searchStr = e?.target?.value?.trim();

    if (suggestions.length) {
      setSuggestions([]);
    }

    if (shouldSuggest && searchStr.length > 2) {
      setSuggestions(["Loading..."]);
      try {
        const {
          data = [],
          status = 400,
          statusText = "Error",
        } = await getProductSuggestions(searchStr);

        if (status !== 200) {
          // eslint-disable-next-line no-throw-literal
          throw { status, statusText };
        }

        if (data?.length) {
          const mappedSuggestions = data.map((suggestion) => ({
            isFinalSuggestion: data.length === 1,
            onClick: () => controllerOnChange(suggestion),
            suggestion,
          }));
          setSuggestions(mappedSuggestions);
        } else {
          setSuggestions(["No results"]);
        }
      } catch (err) {
        if ("message" in err) {
          setSuggestions([err.message]);
        } else {
          setSuggestions(["Unknown error"]);
        }
      }
    }

    controllerOnChange(e);
  };

  return (
    <Controller
      render={({ field: { onChange, value } }): React.ReactElement => (
        <FormAutocomplete
          variant="address"
          onChange={handleOnChange(onChange)}
          value={value || ""}
          ariaLabel="Item / product name"
          id={name}
          className="form-autocomplete"
          error={errors?.[name]?.message}
          suggestions={suggestions}
          label={translate("Item/product name")}
        />
      )}
      rules={{
        required: translate("Enter item/product name"),
        maxLength: {
          value: 255,
          message: translate("Item/product name can be at most 255 characters"),
        },
      }}
      name={name}
      control={control}
    />
  );
};

export default ItemNameField;
