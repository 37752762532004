import React from "react";
import dynamic from "next/dynamic";
import {
  ImageUploaderProxyProps,
  Categories,
} from "../../@types/ImageUploaderProxy";

// import ImageUploader from "../ImageUploader/ImageUploader";
const ImageUploader = dynamic(() => import("../ImageUploader/ImageUploader"), {
  ssr: false,
});

const ImageUploaderProxy: React.VFC<ImageUploaderProxyProps> = (props) => {
  let boxes = [];
  const { translate } = props;
  switch (+props.categoryId) {
    case Categories.Handbags:
      boxes = [
        translate("Front"),
        translate("Back"),
        translate("Inside"),
        translate("Base"),
        translate("Condition"),
        translate("Details"),
        translate("Retail tag or sticker"),
        translate("Serial or designer ID"),
      ];
      break;
    case Categories.Jewelry:
    case Categories.Watches:
      boxes = [
        translate("Front"),
        translate("Back"),
        translate("Engraving Detail"),
        translate("Certificate or Paperwork"),
        translate("Condition"),
        translate("Details"),
        translate("Retail tag or sticker"),
        translate("Serial or designer ID"),
      ];
      break;
    case Categories.Shoes:
      boxes = [
        translate("Top"),
        translate("Side"),
        translate("Sole"),
        translate("Condition"),
        translate("Details"),
        translate("Retail Tag or Sticker"),
      ];
      break;
    default:
      boxes = [
        translate("Front"),
        translate("Other Angles"),
        translate("Details"),
        translate("Retail Tag or Sticker"),
      ];
      break;
  }
  return (
    <ImageUploader
      boxes={boxes}
      updateFileIds={props.updateFileIds}
      error={props.error}
      setDisabled={props.setDisabled}
      setImageUploading={props.setImageUploading}
      setImages={props.setImages}
      images={props.images}
      translate={translate}
    />
  );
};

export default ImageUploaderProxy;
