export const cleanDblQuotes = (str: string): string =>
  str ? str.replace(/"/g, "'") : "";

export const formatDateStr = (dateStr: string): string => {
  if (!dateStr) return "unknown";
  try {
    return new Date(dateStr)?.toLocaleDateString();
  } catch {
    return "unknown";
  }
};

/** returns undefined on failed lookup */
export const findValByKey = <O extends object = object, R = unknown>(
  obj: O,
  key: string,
  type?: string
): R => {
  let result: R;
  if (obj[key] && type === undefined) {
    return obj[key];
  }
  if (obj[key] && type !== undefined && typeof obj[key] === type) {
    return obj[key];
  }
  const children = Object.keys(obj).filter((k) => typeof obj[k] === "object");
  for (let i = 0; i < children.length; i++) {
    result = findValByKey(obj[children[i]], key, type);
    if (result) {
      break;
    }
  }
  return result;
};

/** keys as a string[] can be used with type arg, otherwise use the keys-dict */
/** keys as an array returns the first matching element */
/** keys as a dict returns a dict */
export const findValByKeys = <O extends object = object, R = unknown>(
  obj: O,
  keys: string[] | { key: string; type?: string }[],
  keyType?: string
): R | Record<string, R> => {
  const result: Record<string, R> = {};
  for (let i = 0; i < keys.length; i++) {
    let key: string;
    let type: string;
    const data = keys[i];
    if (typeof data === "string") {
      key = data;
      type = keyType;
    } else {
      ({ key, type } = data);
    }
    const res = findValByKey<O, R>(obj, key, type);
    if (res) {
      // if the input is a string[]
      if (typeof data === "string") {
        return res;
      }
      result[key] = res;
    }
  }
  return result;
};
