import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const MoreQuestions = ({ sybContentfulData }): React.ReactElement => (
  <div className="more-questions-container">
    <h3 className="h3 more-questions-header">
      {sybContentfulData.moreQuestionsHeader}
    </h3>
    <ul>
      {sybContentfulData.moreQuestions.map((link, i) => (
        <li key={i}>{documentToReactComponents(link.content)}</li>
      ))}
    </ul>
  </div>
);
export default MoreQuestions;
