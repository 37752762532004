/* eslint-disable */
import { StateFile } from "./ImageUploader";

export enum Categories {
  Shoes = 168,
  Jewelry = 175,
  Handbags = 233,
  Accessories = 164,
  Watches = 265,
  Other = 0,
}

export interface ImageUploaderProxyProps {
  images: StateFile[];
  setImages: (images: StateFile[]) => void;
  categoryId: Categories;
  updateFileIds: (ids: number[]) => void;
  setImageUploading: (status: boolean) => void;
  error?: string;
  setDisabled?: (disabled: boolean) => void;
  translate: (string: string) => string;
}
