import React from "react";
import { Icon } from "@fashionphile/component-library";

const Loading = (): React.ReactElement => (
  <div className="loading-container">
    <Icon
      name="icon-logo-knot"
      size="xl"
      className="loading-knot-logo"
      variant="light-grey"
    />
  </div>
);

export default Loading;
