import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Modal } from "@fashionphile/component-library";

const ConsignmentModal: React.FC<{
  setConsignment: Dispatch<SetStateAction<boolean>>;
}> = ({ setConsignment }) => {
  const handleClose = useCallback(() => {
    setConsignment(false);
  }, [setConsignment]);

  const description = useMemo(
    () => (
      <div className="consignment-modal-container">
        <h2 className="consignment-modal-header">
          Why Consign the Fashionphile Way?
        </h2>
        <p className="body-lg">
          Receive maximum payment for your preowned luxury accessories by
          selling them on consignment. Our low fees and quick turnaround ensures
          that your items receive top dollar, professional presentation, and
          maximum exposure. This process typically takes between two and ten
          weeks.
        </p>
        <p className="body-lg">
          <b>
            Three reasons why Fashionphile is THE place to sell your preowned
            luxury accessories:
          </b>
        </p>
        <ol>
          <li>
            <b>Low Fees:</b> Do the math! Our base fee is only 30% of the
            selling price (you keep 70%), but for bags selling for over $3,000
            the tiered fee structure drops to only 15% (you keep 85% of the
            portion of the selling price above $3,000).
          </li>
          <li>
            <b>Maximum Exposure:</b> Fashionphile is the industry-leading resale
            destination. We provide the largest selection of authentic preowned
            luxury accessories. Shoppers looking for affordable luxury find us
            in many ways: our website which receives high volume shopping
            traffic, boutique locations located in the finest parts of Beverly
            Hills, San Francisco, San Diego, and New York City, and our
            easy-to-use mobile shopping application.
          </li>
          <li>
            <b>Consignor Accounts:</b> Fashionphile has the most transparent
            consignment process. You can track the progress of your items from
            arrival through the preparation process, and all the way to the
            item&apos;s sale and our payment to you.
          </li>
        </ol>
      </div>
    ),
    []
  );
  return (
    <Modal
      size="md"
      ariaLabel="Consignment Modal"
      titleId="customModalTitle"
      descriptionId="customModalDescription"
      title="Consignment"
      description={description}
      closeOnClick={handleClose}
      buttonOnClick={handleClose}
      buttonContent=""
    />
  );
};
export default ConsignmentModal;
