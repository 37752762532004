import React from "react";
import { Controller } from "react-hook-form";
import { SelectInput } from "@fashionphile/component-library";

const name = "currentBrand";
const BrandSelect = ({
  errors,
  control,
  brands,
  hasCurrentBrand,
  currentBrand,
  translate,
}): React.ReactElement => (
  <Controller
    render={({ field: { onChange } }): React.ReactElement => (
      <SelectInput
        label={translate("Designer")}
        id="Designer"
        ariaLabel="designerField"
        onChange={onChange}
        error={errors?.[name]?.message}
        defaultValue={hasCurrentBrand ? currentBrand : "-1"}
      >
        {brands.map(([brandId, brandData], i) => (
          <option value={brandId} key={i}>
            {brandData.name}
          </option>
        ))}
      </SelectInput>
    )}
    rules={{
      required: translate("Please select a designer"),
    }}
    name={name}
    control={control}
  />
);

export default BrandSelect;
