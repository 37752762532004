import React from "react";
import { PendingItem } from "../../@types/PendingItem";

interface StaticProps {
  images: PendingItem["attributes"]["images"];
  handleClickQuotes: () => void;
}

const SubmissionsThumbnail = ({
  images = [],
  handleClickQuotes,
}: StaticProps): React.ReactElement => (
  <a tabIndex={0} onClick={handleClickQuotes} onKeyPress={handleClickQuotes}>
    <img
      src={images[0]?.url || "https://www.fashionphile.com/images/knot.svg"}
      width="96px"
      height="96px"
      style={{ marginRight: "8px", marginBottom: "8px" }}
      alt="Previous submission sell your bag"
    />
  </a>
);

export default SubmissionsThumbnail;
