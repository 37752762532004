import React from "react";
import { PendingItem } from "../../@types/PendingItem";
import SubmissionsThumbnail from "./SubmissionsThumbnailComponent";

interface Props {
  email: string;
  prevSubmissions: PendingItem[];
}
const PreviousSubmissions = ({
  email,
  prevSubmissions,
}: Props): React.ReactElement => {
  if (!email && prevSubmissions.length === 0) {
    return;
  }
  const handleClickQuotes = (): void => {
    window.parent.location.href = `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/account/sales/quotes`;
  };

  const prevSubmissionsThumbs = prevSubmissions.map((item, i) => (
    <SubmissionsThumbnail
      images={item?.attributes?.images}
      key={i}
      handleClickQuotes={handleClickQuotes}
    />
  ));

  const mobilePrevSubmissionsThumbs = prevSubmissions
    .slice(0, 4)
    .map((item, i) => (
      <SubmissionsThumbnail
        images={item?.attributes?.images}
        key={i}
        handleClickQuotes={handleClickQuotes}
      />
    ));

  return (
    <div className="previous-submissions-container">
      <h3 className="h3">You recently submitted</h3>
      <div className="previous-submissions-desktop">
        {prevSubmissionsThumbs}
      </div>
      <div className="previous-submissions-mobile">
        {mobilePrevSubmissionsThumbs}
      </div>
      <div className="previous-quotes">
        <p>
          <a
            tabIndex={0}
            onClick={handleClickQuotes}
            onKeyPress={handleClickQuotes}
          >
            View all submissions
          </a>
        </p>
      </div>
    </div>
  );
};

export default PreviousSubmissions;
