import React, { useEffect, useContext, useCallback, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { Document } from "@contentful/rich-text-types";
import axios from "axios";
import { Draft } from "../../@types/draft";
import { getUserContext } from "../../components/context/UserContext";
import axiosInstance, { axiosInstanceNoAuth } from "../../API/axios-config";
import { TranslateContext } from "../../components/context/TranslateContext";
import Layout, { LayoutProps } from "../../components/Layouts/main";
import PreviousSubmissions from "../../components/sellYourBagPage/PreviousSubmissionsComponent";
import HowItWorks from "../../components/sellYourBagPage/HowItWorksComponent";
import SellYourBagBody from "../../components/sellYourBagPage/SellYourBagBody";
import LoginState from "../../components/sellYourBagPage/LoginStateComponent/LoginStateComponent";
import MoreQuestions from "../../components/sellYourBagPage/MoreQuestionsComponent";
import QuoteInProgress from "../../components/sellYourBagPage/QuoteInProgress";
import { client } from "../../helpers/contentful/clientHelper";
import {
  getFooterData,
  getNavData,
  getRotator,
} from "../../helpers/layout/getHeaderFooterProps";
import { mapCookieFooterData } from "../../helpers/layout/mapCookieFooterProps";
import {
  getLinks,
  getLegalTextData,
} from "../../helpers/sellYourBag/getSellYourBagLayoutProps";
import ConsignmentModal from "../../components/modals/ConsignmentModal";
import ContentfulHead, {
  HeadMetadata,
} from "../../components/contentful/ContentfulHead";
import { isRegionEU, SupportedLocale } from "../../helpers/consts/locale";
import ClientBoundary from "../../components/ClientBoundary/ClientBoundary";
import { COOKIES, getCookieDomain } from "../../events/cookies";
import Loading from "../../components/Loading/Loading";
import ModalBanned from "../../components/sellYourBagPage/modals/ModalBanned";

interface StaticProps extends LayoutProps {
  legalText: Document;
  sybCookieFooterData: LayoutProps["cookieFooterData"];
  sybCookieFooterDataEU: LayoutProps["cookieFooterData"];
  sybPageData: any;
  sybPageDataEU: any;
  modalsData: any[];
  pageMetadata: HeadMetadata;
}

const SellYourBag = ({
  rotatorData,
  navData,
  footerData,
  legalText,
  sybCookieFooterData,
  sybCookieFooterDataEU,
  sybPageData = {},
  sybPageDataEU = {},
  modalsData,
  pageMetadata,
}: StaticProps): React.ReactElement => {
  const {
    user: { email, banned, sellerBanned },
    loading,
  } = getUserContext();
  const [draftEmail, setDraftEmail] = useState<string>(null);
  const _translate = useContext(TranslateContext);
  const { contextLocale } = _translate;
  const sybContentfulData =
    isRegionEU && contextLocale === SupportedLocale.FR
      ? sybPageDataEU
      : sybPageData;
  const sybCookieFooterContentfulData =
    isRegionEU && contextLocale === SupportedLocale.FR
      ? sybCookieFooterDataEU
      : sybCookieFooterData;
  const { subHeader, mainHeader } = sybContentfulData;

  const router = useRouter();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DEBUG_PKJ && router?.query?.port_key_j) {
      const domain = getCookieDomain();
      Cookies.set(COOKIES.PKJ, router.query.port_key_j as string, { domain });
    }
  }, [router?.query]);

  const [prevSubmissions, setPrevSubmissions] = useState([]);

  const [consignment, setConsignment] = useState(false);

  // draft
  const [draftContent, setDraftContent] = useState<Draft>({
    images: [],
    brandId: null,
    quoteId: null,
    name: null,
    email: null,
  });

  const resetDraft = (): void => {
    setDraftContent({
      images: [],
      brandId: null,
      quoteId: null,
      name: null,
      email: null,
    });
  };

  const getPrevSubmissions = useCallback(async () => {
    try {
      const { data } = (await axiosInstance.get<{ data: any[] }>(
        `${process.env.NEXT_PUBLIC_API_URL}/pending-items/previous-submissions`
      )) || { data: { data: [] } };
      setPrevSubmissions(data.data);
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    }
  }, [prevSubmissions]);

  useEffect(() => {
    getPrevSubmissions();
    if (router.asPath === "/sell-your-bag/consignment") {
      setConsignment(true);
    }
  }, []);

  useEffect(() => {
    const emailAddr = draftEmail || email;
    if (emailAddr) {
      setDraftEmail(null);
    }
  }, [email, draftEmail]);

  // query params
  const { query } = router;
  useEffect(() => {
    if (
      router.query.hasOwnProperty("referrer") ||
      router.query.hasOwnProperty("brand_id") ||
      router.query.hasOwnProperty("category_id") ||
      router.query.hasOwnProperty("name")
    ) {
      const domain = getCookieDomain();
      Cookies.set(COOKIES.QUERY, JSON.stringify(router.query), {
        domain,
      });
    } else {
      Cookies.remove(COOKIES.QUERY);
    }
  }, [query]);

  if (!sybPageData || !sybPageDataEU) {
    router.push("/404");
    return;
  }

  useEffect(() => {
    if (query?.key) {
      (async () => {
        try {
          const { data } = await axiosInstanceNoAuth.get(
            `${
              process.env.NEXT_PUBLIC_NODE_APP_URL
            }/quotes/drafts?linkKey=${encodeURIComponent(
              query?.key?.toString()
            )}`
          );
          setDraftEmail(data.email);
          setDraftContent(data);
        } catch (e) {
          resetDraft();
        }
      })();
    } else {
      resetDraft();
    }
  }, [query]);

  if (draftContent?.quoteId && draftContent?.email === email) {
    const { images, brandId, quoteId, name } = draftContent;
    const thumbnailUrl = images?.[0]?.url;
    return (
      <Layout
        rotatorData={rotatorData}
        navData={navData}
        footerData={footerData}
        cookieFooterData={sybCookieFooterContentfulData}
      >
        <QuoteInProgress
          quoteId={quoteId}
          currentBrand={brandId}
          thumbnailUrl={thumbnailUrl}
          name={name}
        />
      </Layout>
    );
  }

  return (
    <Layout
      rotatorData={rotatorData}
      navData={navData}
      footerData={footerData}
      cookieFooterData={sybCookieFooterContentfulData}
    >
      <ContentfulHead {...pageMetadata}>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_WEB_APP_URL}/sell-your-bag`}
        />
      </ContentfulHead>
      {loading && <Loading />}
      <div>
        <div className="syb-desktop">
          <div className="syb">
            <div id="syb-banner" className="banner-wrapper">
              <div className="banner container">
                <div className="title-wrapper">
                  <h1 className="h1-semibold-desktop-largest">{mainHeader}</h1>
                  <p className="body-lg">{subHeader}</p>
                </div>
              </div>
            </div>
            <div className="container syb-app">
              <div className="form-wrapper-container">
                <div className="visible-xs">
                  {/* mobile - prev submissions */}
                  <ClientBoundary>
                    {email && prevSubmissions.length > 0 && (
                      <PreviousSubmissions
                        email={email}
                        prevSubmissions={prevSubmissions}
                      />
                    )}
                  </ClientBoundary>
                </div>
                <ClientBoundary>
                  {email ? (
                    <>
                      {(banned || sellerBanned) && <ModalBanned />}
                      <SellYourBagBody
                        {...{
                          updateSubmissions: getPrevSubmissions,
                          itemDetailsModal:
                            modalsData?.[0]?.fields?.modalTextCopy,
                          imageUploadModal:
                            modalsData?.[1]?.fields?.modalTextCopy,
                          legalText,
                          draftContent,
                          resetDraft,
                        }}
                      />
                    </>
                  ) : (
                    <LoginState sybContentfulData={sybContentfulData} />
                  )}
                </ClientBoundary>
              </div>

              <div className="col-wrapper">
                <div className="hidden-xs">
                  {/* desktop - prev submissions */}
                  <ClientBoundary>
                    {email && prevSubmissions.length > 0 && (
                      <PreviousSubmissions
                        email={email}
                        prevSubmissions={prevSubmissions}
                      />
                    )}
                  </ClientBoundary>
                </div>
                <div className="how-it-works">
                  <HowItWorks sybContentfulData={sybContentfulData} />
                </div>
                <div className="more-questions">
                  <MoreQuestions sybContentfulData={sybContentfulData} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {consignment && <ConsignmentModal setConsignment={setConsignment} />}
      </div>
    </Layout>
  );
};

export const getStaticProps = async (): Promise<{ props: StaticProps }> => {
  const legalText = await client
    .getEntries({
      content_type: "legalConsentNearCta",
    })
    .then((data) => getLegalTextData(data));
  const sybPageData = await client
    .getEntries({
      content_type: "sellYourBagLayout",
      include: 3,
      locale: "en-US",
    })
    .then((data) => getLinks(data));
  const sybPageDataEU = await client
    .getEntries({
      content_type: "sellYourBagLayout",
      include: 3,
      locale: "fr-FR",
    })
    .then((data) => getLinks(data));
  const rotatorData = await client
    .getEntries({
      content_type: "navigationRotator",
    })
    .then((data) => getRotator(data));
  const navData = await client
    .getEntries({
      content_type: "topNavigationMenu",
      include: 3,
    })
    .then((data) => getNavData(data));
  const footerData = await client
    .getEntries({
      content_type: "footer",
      include: 3,
    })
    .then((data) => getFooterData(data));

  const modalsData = await client
    .getEntries({
      content_type: "modalMedium",
    })
    .then((data) => data?.items);

  const pageMetadata = await client
    .getEntry<StaticProps["pageMetadata"]>("6SL4pUIB46WWY5gt9FxZBu")
    .then((data) => data.fields);

  const sybCookieFooterData = await client
    .getEntry("1n2uP4uB2KsepY10bYwonD", {
      locale: "en-US",
    })
    .then((data) => mapCookieFooterData(data));

  const sybCookieFooterDataEU = await client
    .getEntry("1n2uP4uB2KsepY10bYwonD", {
      locale: "fr-FR",
    })
    .then((data) => mapCookieFooterData(data));

  return {
    props: {
      rotatorData,
      navData,
      footerData,
      legalText,
      sybCookieFooterData,
      sybCookieFooterDataEU,
      sybPageData,
      sybPageDataEU,
      modalsData,
      pageMetadata,
    },
  };
};

export default SellYourBag;
