import React from "react";
import { Modal } from "@fashionphile/component-library";

const ModalError = ({
  setShowSubmitErrorModal,
  submit,
}): React.ReactElement => {
  const description = (): React.ReactElement => (
    <>There was an error on our end. Please try submitting again.</>
  );
  return (
    <Modal
      size="sm"
      ariaLabel="ErrorsModal"
      titleId="customModalTitle"
      descriptionId=""
      title="Error"
      description={description()}
      buttonContent="Try again"
      closeOnClick={(): void => {
        setShowSubmitErrorModal(false);
      }}
      buttonOnClick={(): void => {
        submit();
        setShowSubmitErrorModal(false);
      }}
    />
  );
};
export default ModalError;
