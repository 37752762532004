import React from "react";
import { Modal } from "@fashionphile/component-library";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { optionsModal } from "../../../helpers/contentful/options";

const ModalComments = ({
  itemDetailsModal,
  setShowCommentsModal,
}): React.ReactElement => {
  const description = (): React.ReactElement => (
    <>{documentToReactComponents(itemDetailsModal, optionsModal)}</>
  );
  return (
    <Modal
      className="item-details-modal"
      size="md"
      ariaLabel="CommentsModal"
      titleId="customModalTitle"
      descriptionId=""
      title="Helpful tips"
      description={description()}
      closeOnClick={(): void => {
        setShowCommentsModal(false);
      }}
      buttonOnClick={(): void => {
        setShowCommentsModal(false);
      }}
      buttonContent="Close"
    />
  );
};
export default ModalComments;
