import React from "react";
import Head from "next/head";

export interface HeadMetadata {
  metaTitle: string;
  metaDescription: string;
  metaKeywords?: string;
  ogtitle?: string;
  ogdescription?: string;
  ogimage?: { metadata: any; sys: any; fields: any };
  ogimagealt?: string;
  children?: React.ReactNode;
}

/**
 * Takes a HeadMetadata object based on Contentful page-metadata template
 * and returns formatted NextJS Head element.
 *
 * Accepts children alike Head
 */
const ContentfulHead: React.FC<HeadMetadata> = ({
  metaTitle,
  metaDescription,
  metaKeywords = "",
  ogtitle = "",
  ogdescription = "",
  ogimage = null,
  ogimagealt = "",
  children,
}) => (
  <Head>
    <title>{metaTitle}</title>
    <meta key="description" name="description" content={metaDescription} />
    <meta key="keywords" name="keywords" content={metaKeywords} />
    <meta key="og:title" property="og:title" content={ogtitle} />
    <meta
      key="og:description"
      property="og:description"
      content={ogdescription}
    />
    <meta
      key="og:image"
      property="og:image"
      content={ogimage?.fields.file.url}
    />
    <meta
      key="og:image:type"
      property="og:image:type"
      content={ogimage?.fields.file.contentType}
    />
    <meta key="og:image:alt" property="og:image:alt" content={ogimagealt} />
    {children}
  </Head>
);

export default ContentfulHead;
