import React from "react";
import { Controller } from "react-hook-form";
import { SelectInput } from "@fashionphile/component-library";

const name = "currentCategory";
const CategorySelect = ({
  errors,
  control,
  hasCurrentCategory,
  categoryList,
  currentCategory,
  translate,
}): React.ReactElement => (
  <Controller
    render={({ field: { onChange } }): React.ReactElement => (
      <SelectInput
        label={translate("Category")}
        id="Category"
        ariaLabel="categoryField"
        onChange={onChange}
        error={errors?.[name]?.message}
        disabled={!categoryList?.length}
        defaultValue={hasCurrentCategory ? currentCategory : "-1"}
      >
        {categoryList.map((category, i) => (
          <option value={category.id} key={i}>
            {category.name}
          </option>
        ))}
      </SelectInput>
    )}
    rules={{
      required: translate("Please select a category"),
    }}
    name={name}
    control={control}
  />
);

export default CategorySelect;
